// local imports
import shippingIcon from "./assets/free-shiping-icon.svg";
import paymentIcon from "./assets/quick-payment-icon.svg";
import supportIcon from "./assets/support-icon.svg";
import productOneIcon from "./assets/decor.png";
import productTwoIcon from "./assets/ac.png";
import productThreeIcon from "./assets/furniture.png";
import mixedRealityVFX from "./assets/mixedRealityVFX.png";
import interiorRenders from "./assets/interiorRenders.gif";
import exteriorRenders from "./assets/exteriorRenders.webp";
import walkthrough from "./assets/walkthrough.png";
import graphicDesigning from "./assets/graphicDesigning.webp";
import logoDesigning from "./assets/logoDesigning.png";
import websiteDevelopment from "./assets/websiteDevelopment.gif";
import plantIcon from "./assets/plant-icon.svg";
import sunIcon from "./assets/sun-icon.svg";
import waterIcon from "./assets/water-icon.svg";
import temperatureIcon from "./assets/temperature-icon.svg";

export const heroTitle = "Bring Serenity to Your Place With Interior Designs";
export const heroSubtitle =
  "Find dreamy interiors for your home with us, and we will make it happen.";

export const services = [
  {
    title: "Free Shipping",
    subtitle: "No charge for your delivery",
    icon: shippingIcon,
  },
  {
    title: "Quick Payment",
    subtitle: "100% secure",
    icon: paymentIcon,
  },
  {
    title: "24/7 Support",
    subtitle: "Don't hesitate to contact us",
    icon: supportIcon,
  },
];

export const productsTitle = "What can we do for you?";
export const productsBtnText = "Reach out on WhatsApp";

export const products = [
  {
    title: "Home Decor",
    price: "Contact Us",
    img: productOneIcon,
  },
  {
    title: "Maintainance",
    price: "Contact Us",
    img: productTwoIcon,
  },
  {
    title: "Furniture",
    price: "Contact Us",
    img: productThreeIcon,
  },
  {
    title: "Mixed Reality VFX",
    price: "Contact Us",
    img: mixedRealityVFX,
  },
  {
    title: "Interior Renders",
    price: "Contact Us",
    img: interiorRenders,
  },
  {
    title: "Exterior Renders",
    price: "Contact Us",
    img: exteriorRenders,
  },
  {
    title: "Walkthrough",
    price: "Contact Us",
    img: walkthrough,
  },
  {
    title: "Graphic Desigining",
    price: "Contact Us",
    img: graphicDesigning,
  },
  {
    title: "Logo",
    price: "Contact Us",
    img: logoDesigning,
  },
  {
    title: "Website Development",
    price: "Contact Us",
    img: websiteDevelopment,
  },
];

export const referenceTitle = "Decor That Matches Your Vibe";
export const referenceSubtitle = "";

export const careTitle = "Message From Our Director";
export const careSubtitle = "Our interior design company specializes in transforming spaces to feel like home, blending style and functionality. We offer a full range of services, from decor and furniture selection to maintenance and renovations. Our expert team works closely with clients to create personalized designs that reflect their unique tastes and needs. Whether you're looking to revamp a single room or your entire home, we provide comprehensive solutions to make your vision a reality. Let us help you create a space that's not just beautiful, but truly yours.";

export const careList = [
  {
    title: "Adjust Lighting",
    subtitle:
      "When caring for houseplants, make sure the room temperature is neither too cold nor too hot.",
    img: sunIcon,
  },
  {
    title: "Don't water too often",
    subtitle:
      "Watering ornamental houseplants does not have to be done every day.",
    img: waterIcon,
  },
  {
    title: "Choose the right container.",
    subtitle:
      "Houseplants will need different containers depending on their type and size.",
    img: plantIcon,
  },
  {
    title: "Fertilize regularly",
    subtitle:
      "The nutrients most indoor houseplants need are nitrogen for balance and potassium for stem strength.",
    img: temperatureIcon,
  },
];

export const newsletter = "Newsletter";

export const footerLinksColumnOne = [
  {
    title: "Support",
    links: ["About Us", "Careers", "Blog"],
  },
  {
    title: "Useful Links",
    links: ["Payment & Tax", "Terms of service", "Privacy Policy"],
  },
];

export const footerLinksColumnTwo = [
  {
    title: "Our Menu",
    links: ["Best Product", "Categories"],
  },
  {
    title: "Address",
    links: [
      "ملك محمد بن حمد بن سيف الرمحى-المطينة E5- مكتب رقم 201",
      "fixandfurbishdecor@outlook.com",
    ],
  },
];
